.cardBg {
  background-color: #252d4a;
  width: 450px;
  min-height: 200px;
  height: min-content;
  border-radius: 15px;
  padding: 20px;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.score-section {
  display: flex;
  font-size: 24px;
  align-items: center;
}

/* QUESTION/TIMER/LEFT SECTION */
.question-section {
  width: 100%;
  position: relative;
}

.question-count {
  margin-bottom: 20px;
  color: #ffffff;

}

.question-count span {
  font-size: 28px;
  color: #ffffff;
}

.question-text {
  margin-bottom: 12px;
  color: #ffffff;

}

.timer-text {
  background: rgb(230, 153, 12);
  padding: 15px;
  margin-top: 20px;
  margin-right: 20px;
  border: 5px solid rgb(255, 189, 67);
  border-radius: 15px;
  text-align: center;
}

/* ANSWERS/RIGHT SECTION */
.answer-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cardButton {
  width: 100%;
  font-size: 16px;
  color: #ffffff;
  background-color: #252d4a;
  border-radius: 15px;
  display: flex;
  padding: 5px;
  justify-content: flex-start;
  align-items: center;
  border: 5px solid #234668;
  cursor: pointer;
}

.selectedCardButton {
  width: 100%;
  font-size: 16px;
  color: #ffffff;
  background-color: #252d4a;
  border-radius: 15px;
  display: flex;
  padding: 5px;
  justify-content: flex-start;
  align-items: center;
  border: 5px solid #234668;
  cursor: pointer;
  background-color: #555e7d;
}

.correct {
  background-color: #2f922f;
}

.incorrect {
  background-color: #ff3333;
}

.cardButton:hover {
  background-color: #555e7d;
}

.cardButton:focus {
  outline: none;
}

@media (max-width:480px) {
  .cardBg {
    background-color: #252d4a;
    width: 350px;
    min-height: 155px;
    height: min-content;
    border-radius: 15px;
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;
  }
}